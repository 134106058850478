@import './../sass/reuse';

.socialMenu {
  font-size: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  @include desktop {
    display: inline-block;
    li {
      display: inline-block;
    }
  }

  li {
    margin-right: $gap / 4;
    &:last-child {
      margin-right: 0;
    }
  }
}

.iconLink {
  width: 42px;
  height: 42px;

  @include mobile {
    width: 30px;
    height: 30px;
    margin-bottom: 15px;
  }
  svg {
    position: relative;
    top: 10px;
    width: 42px;
    height: 42px;
    fill: var(--social-icon-color);

    @include mobile {
      width: 30px;
      height: 30px;
    }
  }

  a {
    &:hover {
      svg {
        fill: #e728ea;
      }
    }
  }
}
