@import './../sass/reuse';

.footer {
  font-weight: $weight-medium; //500
  padding-top: 94px;
  padding-bottom: 94px;

  @include desktop {
    height: 514px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  @include mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.labFooter {
  @include desktop {
    justify-content: space-between;
    .navMenu {
      float: right;
    }
  }
  @include widescreen {
    justify-content: flex-start;
    column-gap: 15%;
  }
  .navMenu {
    display: flex;
    justify-content: center;
  }
}
.footerContainer {
  & > ul {
    margin-bottom: $gap / 2;
  }
  @include tablet {
    padding-left: $gap / 2;
  }
  &:not(.labFooterContainer) {
    @include widescreen {
      padding-left: $gap * 3;
      border-left: 1px solid var(--body-color);
    }
  }
}
.labFooterLogoContainer {
  display: flex;
  width: fit-content;
  align-items: center;
  @include mobile {
    margin-bottom: 30px;
  }
  .logo {
    @include mobile {
      margin-bottom: 0;
    }
    @include desktop {
      margin-right: 0;
    }
  }
}

.logo {
  width: 100px;
  display: block;
  margin: 0 auto;
  text-align: center;

  @include mobile {
    width: 48px;
    margin-bottom: 30px;
  }

  @include desktop {
    flex: 0 0 auto;
    margin-left: $gap;
    margin-right: $gap * 3;
  }
}
.labLogo {
  padding-left: $gap;
  margin-left: $gap;
  border-left: 1px solid var(--body-color);
}

.navMenu {
  margin-top: $gap / 2;
  font-size: $size-6;

  @include mobile {
    display: flex;
    flex-direction: column;
    max-width: 202px;
    margin: 0 auto $gap / 2;
    font-size: 0.8125rem;
  }

  @include tablet {
    text-align: center;
    font-size: 20px;
    max-width: 502px;
    margin: $gap auto;
  }

  @include desktop {
    max-width: none;
  }

  .footerLink {
    &:hover {
      color: $purple;
      cursor: pointer;
    }
  }

  li {
    float: left;
    margin-bottom: $gap / 2;

    &:first-child {
      &:before {
        margin: 0;
        content: '';
      }
    }

    &:before {
      content: '|';
      margin-left: 3 * $gap / 8;
      margin-right: 3 * $gap / 8;

      @include mobile {
        content: '';
        margin-left: 0;
        margin-right: 0;
      }
    }

    @include mobile {
      text-align: center;
    }
  }
}

.copyright {
  font-size: 1.0625rem;
  text-align: center;

  @include mobile {
    font-size: 0.75rem;
  }

  @include desktop {
    display: inline-block;
    margin-left: $gap;
    position: relative;
    top: -5px;
  }
}
