$black: #131313;
$white: #fff;
$purple: #e728ea;
$grey-light: #f0f0f0;
$grey: #464646;
$grey-dark: #2f2f2f;
$cyan: #49c7f2;
$green: #28eab6;

$family-sans-serif: 'AvenirNext', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  'Helvetica', 'Arial', sans-serif;

$family-sans-serif-light: 'AvenirNext Light', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

// Responsive
$fullhd: 1532px;

// Elements
$body-size: 16px;
$body-background-color: #1f1f1f;
$body-color: var(--body-color);

$link: var(--link-color);
$link-hover: $purple;

$radius: 8px;

// Forms
$help-size: 16px;
$control-border-width: 4px;

// Table
$table-color: $white;
$table-background-color: transparent;
$table-row-hover-background-color: $grey-dark;
$table-head-cell-color: $white;
